import React from "react";

export default function Transaction({ trans }) {
  return (
    <div>
      <div
        className="columns"
        style={{ marginBottom: "20px", marginLeft: "0px", width: "80%" }}
      >
        <div className="column blue-left">
          <div class="field">
            <label class="label">Item / Test</label>
            <span>{trans.Description}</span>
          </div>
        </div>
        <div className="column has-text-right">
          <div class="field">
            <label class="label">Quantity</label>
            <span>{trans.Quantity}</span>
          </div>
        </div>
        <div className="column has-text-right">
          <div class="field">
            <label class="label">Unit Cost</label>
            <span>${trans.UnitPrice}</span>
          </div>
        </div>
        <div className="column has-text-right">
          <div class="field">
            <label class="label">Line Total</label>
            <span>${trans.ListPrice}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
