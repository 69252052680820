import React from "react";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Zoom from "react-reveal/Slide";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faTimes } from "@fortawesome/free-solid-svg-icons";
import Transaction from "./Transaction";
import DownloadAnchor from "../common/DownloadAnchor";
import CheckoutButton from "./Payment";
import Lottie from "react-lottie";
import SuccessLottie from "../../images/success.json";
import InvoiceProgressBar from "./InvoiceProgressbar";
import Dropdown from "../common/Dropdown";
import { getInvoice } from "../../services/invoiceService";
import { getInvoiceReport } from "../../services/reportService";
import Pulse from "react-reveal/RubberBand";
import CommonLoader from '../common/CommonLoader'
const ToDate = (input) => {
	if (input) {
		return new Date(input).toLocaleDateString();
	} else {
		return "";
	}
};

export default function Invoice() {
	const { invoiceNumber } = useParams();
	const [invoice, setInvoice] = useState();
	const [percentComplete, setPercentComplete] = useState(0);
	const [successModalVisible, setSuccessModalVisible] = useState(false);
	const [zoomAnimationVisible, setZoomAnimationVisible] = useState(true);

	//Effect to run on first render
	useEffect(() => {
		const query = new URLSearchParams(window.location.search);
		if (query.get("success")) {
			setZoomAnimationVisible(false);
			setSuccessModalVisible(true);
			setTimeout(() => {
				setSuccessModalVisible(false);
				//Trigger API call to mark invoice as payment pending
			}, 3500);
		}
		if (query.get("canceled")) {
		}
	}, []);

	//Effect to run when invoiceNumber is given
	useEffect(() => {
		async function GetOrder() {
			let data = await getInvoice(invoiceNumber);
			setInvoice(data);
		}
		GetOrder();
	}, [invoiceNumber]);

	//Effect to run when invoice is loaded
	useEffect(() => {
		if (invoice?.InvoiceNumber) {
			let percent = 0;
			setTimeout(() => {
				if (invoice?.DateSent) {
					percent = 33;
				}
				if (invoice?.PaymentPending !== null) {
					percent = 66;
				}
				if (invoice?.Deposit !== 0) {
					percent =
						100 - (50 - (invoice?.Deposit / invoice?.InvoiceAmount) * 50);
				}
				setPercentComplete(percent);
			}, 1500);
		}
	}, [invoice]);

	const renderModal = () => {
		return (
			<div class="modal" style={{ display: "block" }}>
				<div class="modal-background"></div>
				<div class="modal-content">
					<p class="image is-4by3">
						<Lottie
							options={{
								loop: false,
								autoplay: true,
								animationData: SuccessLottie,
							}}
							height={500}
							width={500}
						/>
					</p>
				</div>
			</div>
		);
	};

	const RenderBody = () => {
		return (
			<div className="box">
				<div className="columns">
					<div className="column" style={{ paddingBottom: "0px" }}>
						<p
							style={{
								display: "inline-block",
								marginBottom: "8px",
								marginLeft: "22px",
							}}
							className="title has-text-grey-dark"
						>
							Invoice: {invoiceNumber}
						</p>
						{invoice?.PoNumber?.trim()?.length ? (
							<span style={{ marginLeft: "30px" }}>
								PO: {invoice?.PoNumber}
							</span>
						) : (
							""
						)}
					</div>
					<div className="column">
						<Link
							className="is-rounded is-pulled-right is-size-4"
							to="/"
							style={{ marginRight: "25px" }}
						>
							<Pulse cascade delay={2000}>
								<FontAwesomeIcon icon={faTimes} />
							</Pulse>
						</Link>

						<Dropdown
							children={[
								<div class="dropdown-item">
									<DownloadAnchor
										cssClass="has-text-primary is-size-4"
										func={() => getInvoiceReport(invoiceNumber)}
										icon={faFilePdf}
										hoverText="Invoice PDF"
										text="Invoice PDF"
									/>
								</div>,
							]}
						/>
					</div>
				</div>

				<div className="columns">
					<div
						className="column"
						style={{
							padding: "50px",
						}}
					>
						<InvoiceProgressBar
							percentComplete={percentComplete}
							dateSent={invoice?.DateSent}
						/>
					</div>
				</div>

				<div className="section" style={{ paddingTop: "10px" }}>
					<div className="columns">
						<div className="column">
							<div className="field">
								<label className="label">Client</label>{" "}
								<p className="has-text-weight-bold">{invoice?.BillingName}</p>
								<p className="has-text-grey">{invoice?.BillingAddress}</p>
								<p className="has-text-grey">
									{invoice?.BillingCity?.trim()},{" "}
									{invoice?.BillingState?.trim()} {invoice?.BillingZip?.trim()}
								</p>
							</div>
						</div>
						<div className="column">
							<div className="field">
								<label htmlFor="" className="label">
									Total Amount
								</label>
								<span>${invoice?.InvoiceAmount}</span>
							</div>
							<div className="field">
								<label htmlFor="" className="label ">
									Amount Paid
								</label>
								<span>${invoice?.Deposit}</span>
							</div>
						</div>
						<div className="column has-text-centered">
							<div className="field">
								<label htmlFor="" className="label ">
									Balance Due
								</label>
								<span className="is-size-3 has-text-weight-bold">
									${invoice && invoice.InvoiceAmount - invoice?.Deposit}
								</span>
							</div>
							{invoice &&
							invoice.InvoiceAmount - invoice.Deposit === 0 &&
							invoice.PaymentPending == null ? (
								""
							) : (
								<div className="field">
									<span className="is-warning">
										If you have already paid and the status hasn't changed try
										logging out and checking again
									</span>
									<CheckoutButton invoices={invoiceNumber} />
								</div>
							)}
						</div>
					</div>
				</div>

				<hr />

				<div
					className="section"
					style={{ paddingTop: "0px", paddingBottom: "10px" }}
				>
					<p className="subtitle">Order Info</p>
					<div className="columns">
						<div className="column">
							<div className="field">
								<label className="label">Order Number</label>
								<Link
									className="has-text-primary has-text-weight-semibold underscore"
									to={`/order/${invoice?.OrderNumber}`}
								>
									{invoice?.OrderNumber}
								</Link>
							</div>
							<div className="field">
								<label className="label">Received</label>
								<span className="">{ToDate(invoice?.Order?.DateReceived)}</span>
							</div>
							<div className="field">
								<label className="label">Description</label>
								<span className="">{invoice?.Order?.ProjectCategory}</span>
							</div>
						</div>
						<div className="column">
							<div className="field">
								<label htmlFor="" className="label">
									Notes
								</label>
							</div>
							<textarea
								className="textarea"
								name="notes"
								id="notesArea"
								readOnly
								cols="30"
								rows="4"
							>
								{invoice?.ReportNotes}
							</textarea>
						</div>
						<div className="column"></div>
					</div>
				</div>

				<hr />

				<div
					className="section"
					style={{ paddingTop: "0px", paddingBottom: "10px" }}
				>
					<p className="subtitle">Charges</p>
					{invoice?.Transactions?.map((t) => {
						return <Transaction key={t.RecordId} trans={t} />;
					})}
				</div>
			</div>
		);
	};

	return (
		<div>
			{successModalVisible && renderModal()}
			{!invoice ? (
				<>
				<CommonLoader loading={true} color={"primary"} />
				</>
			) : zoomAnimationVisible ? (
				<Zoom top>{RenderBody()}</Zoom>
			) : (
				RenderBody()
			)}
		</div>
	);
}
