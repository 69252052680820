import React, { useState, useEffect } from "react";
import logo from "../images/EnvironmentalLabsLogoHorizontal.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation } from "react-router-dom";

export default function Header({
  logout,
  isMultiClient,
  clearClient,
  accountNumber,
}) {
  const [isDashboard, setIsDashboard] = useState(null);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/client") {
      setIsDashboard(true);
    } else {
      setIsDashboard(false);
    }
  }, []);

  const toggleViewOrDashboard = () => {
    setIsDashboard(!isDashboard);
  };
  return (
    <>
      <nav
        className="navbar transparent"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand" style={{ marginLeft: "0" }}>
          <a href="/">
            <img
              src={logo}
              alt="ELI Logo"
              style={{ maxHeight: "55px", marginTop: "10px" }}
            />
          </a>
        </div>
        {isMultiClient && (
          <div className="navbar-item">
            <div className="buttons">
              <a className="button is-primary is-rounded" onClick={clearClient}>
                Home
              </a>
            </div>
          </div>
        )}

        <div id="navbarBasicExample" className="navbar-menu">
          <div className="navbar-end">
            <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link">
                <FontAwesomeIcon icon={faUser} color="green" size="lg" />
                <span className="ml-2">Account</span>
              </a>
              <div className="navbar-dropdown">
                {isDashboard ? (
                  <Link
                    to={`/dashboard`}
                    className="navbar-item"
                    onClick={toggleViewOrDashboard}
                  >
                    Dashboard
                  </Link>
                ) : (
                  <Link
                    to={`/client`}
                    className="navbar-item"
                    onClick={toggleViewOrDashboard}
                  >
                    View Details
                  </Link>
                  // <div></div>
                )}

                <a className="navbar-item" onClick={logout}>
                  Logout
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
