import React from "react";

const ToDate = (input) => {
  if (input) {
    return new Date(input).toLocaleDateString();
  } else {
    return "";
  }
};

export default function Sample({ sample }) {
  return (
    <div>
      <div
        className="columns"
        style={{ marginBottom: "20px", marginLeft: "0px" }}
      >
        <div className="column blue-left">
          <div class="field">
            <label class="label">Id</label>
            <span>{sample.LabId}</span>
          </div>
        </div>
        <div className="column">
          <div class="field">
            <label class="label">Description</label>
            <span>{sample.SampleDescription}</span>
          </div>
        </div>
        <div className="column">
          <div class="field">
            <label class="label">Collected</label>
            <span>{ToDate(sample.DateCollected)}</span>
          </div>
        </div>
        <div className="column">
          <div class="field">
            <label class="label">Collected By</label>
            <span>{sample.CollectedBy}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
