import React, { useEffect, useState } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useParams } from "react-router-dom";
import { getBottleOrder } from "../../services/bottleOrderService";
import Slide from "react-reveal/Slide";
import Pulse from "react-reveal/RubberBand";
import OrderedBottle from "./OrderedBottle";

const ToDate = (input) => {
  if (input) {
    let date = new Date(input);
    let output = date.toLocaleDateString();
    //handle default date from API
    if (date.getYear() === 1) return "";
    return output;
  } else {
    return "";
  }
};

export default function BottleOrder() {
  const { orderNumber } = useParams();
  const [order, setOrder] = useState();

  useEffect(() => {
    async function GetBottleOrder() {
      if (orderNumber) {
        let data = await getBottleOrder(orderNumber);
        setOrder(data);
      }
    }
    GetBottleOrder();
  }, [orderNumber]);

  return (
    <>
      {!order ? (
        <></>
      ) : (
        <>
          <Slide top>
            <div className="box">
              <div className="columns">
                <div className="column">
                  <p
                    style={{ display: "inline-block" }}
                    className="title has-text-grey-dark"
                  >
                    # {orderNumber}
                  </p>
                </div>
                <div className="column">
                  <Link
                    className="is-rounded is-pulled-right is-size-4"
                    to="/"
                    style={{ marginRight: "25px" }}
                  >
                    <Pulse cascade delay={2000}>
                      <FontAwesomeIcon icon={faTimes} />
                    </Pulse>
                  </Link>
                </div>
              </div>
              <hr />
              <div className="section" style={{ paddingTop: "0px" }}>
                <p className="subtitle has-text-grey-dark">Information</p>
                <hr />
                <div className="columns">
                  <div className="column">
                    <div className="field">
                      <label className="label">Description</label>
                      <span>{order.Description}</span>
                    </div>
                    <div className="field">
                      <label className="label">Type</label>
                      <span>{order.SampleMatrix}</span>
                    </div>
                  </div>
                  <div className="column">
                    <div className="field">
                      <label className="label">Order Date</label>
                      <span>{ToDate(order.OrderDate)}</span>
                    </div>
                    <div className="field">
                      <label className="label">Ship to Client Date</label>
                      <span>{ToDate(order.DueDate)}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="section" style={{ paddingTop: "10px" }}>
                <p class="subtitle has-text-grey-dark">Bottles</p>
                <hr />
                {order.OrderedBottles?.map((s) => {
                  return <OrderedBottle key={s.LabId} bottle={s} />;
                })}
              </div>
            </div>
          </Slide>
        </>
      )}
    </>
  );
}
