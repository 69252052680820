import React, { useState, useEffect } from "react";
import InfoTab from "./InfoTab";
import ShippingTab from "./ShippingTab";
import BillingTab from "./BillingTab";
import ReportTab from "./ReportTab";
import { getClientDetails } from "../../services/clientService";
import CommonLoader from '../common/CommonLoader';
import { useHistory } from "react-router-dom";

export default function Client({ accountNumber }) {
  const [activeTab, setActiveTab] = useState("Shipping");
  const [clientDetails, setClientDetails] = useState(null); 
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  useEffect(() => {
    async function GetDashboardInfo() {
      try {
        let data = await getClientDetails(accountNumber);
        setClientDetails(data);
        setLoading(false)
      } catch (err) {
        console.error(err);
      }
    }
    if (accountNumber) {
      GetDashboardInfo();
    }
  }, [accountNumber]);
  const navigateToDashboard = () => {
    history.push("/dashboard");
  };
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="container">
      {clientDetails ? ( 
        <>
          <div className="tabs is-left">
            <ul>
              {["Shipping", "Billing", "Report"].map((tab) => (
                <li key={tab} className={activeTab === tab ? "is-active" : ""}>
                  <a onClick={() => handleTabClick(tab)}>
                    <span className="icon is-small">{/* Add icon here if needed */}</span>
                    <span>{tab}</span>
                  </a>
                </li>
              ))}
            </ul>
          </div>

          <div className="content">
            <div className="container">
              {/* {activeTab === "Info" && <InfoTab clientDetails={clientDetails} navigateToDashboard={navigateToDashboard}/>} */}
              {activeTab === "Shipping" && <ShippingTab clientDetails={clientDetails} navigateToDashboard={navigateToDashboard}/>}
              {activeTab === "Billing" && <BillingTab clientDetails={clientDetails} navigateToDashboard={navigateToDashboard}/>}
              {activeTab === "Report" && <ReportTab clientDetails={clientDetails} navigateToDashboard={navigateToDashboard} />}
            </div>
          </div>
        </>
      ) : (
        <CommonLoader loading={loading} color={"primary"} />
      )}
    </div>
  );
}
