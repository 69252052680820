import { getJson } from "./networkService";

export async function getInvoice(invoiceNumber) {
  return getJson(`/invoices/${invoiceNumber}`);
}

export async function getInvoices(fromDate, toDate, accountNumber, page, filter, sort) {
  return getJson(
      `/invoices/account/${accountNumber}?page=${page}&filter=${filter}&sortOrder=${sort}&fromDate=${fromDate}&toDate=${toDate}`
  );
}
