import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";

export default function DownloadAnchor({
  func,
  cssClass,
  icon,
  hoverText,
  text,
}) {
  const [loading, setLoading] = useState(false);

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      onClick={async (e) => {
        setLoading(true);
        e.preventDefault();
        await func();
        setLoading(false);
      }}
      title={hoverText}
      style={{ marginRight: "8px", marginLeft: "8px", color: "grey" }}
    >
      {loading ? (
        <FontAwesomeIcon className={`fa-spin ${cssClass}`} icon={faSpinner} />
      ) : (
        <FontAwesomeIcon
          icon={icon}
          style={{ height: "24px", width: "28px" }}
          className={`${cssClass}`}
        />
      )}{" "}
      {text}
    </a>
  );
}
