import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLock,
  faFileInvoice,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import logo from "../images/EnvironmentalLabsLogoHorizontal.png";
import {
  Authenticate,
  SignUp,
  SetPassword,
  ResetPasswordRequest,
  ResetPasswordResponse,
} from "../services/userService";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import ReactGA from "react-ga";
import LogRocket from "logrocket";
import { Link, useNavigate } from "react-router-dom";

ReactGA.initialize("UA-193251731-1");

export default function Login({ setToken }) {
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [loading, setLoading] = useState(false);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [error, setError] = useState();
  const [signUp, setSignUp] = useState(false);
  const [createPassword, setCreatePassword] = useState(false);
  const [account, setAccount] = useState();
  const [showPasswords, setShowPasswords] = useState(false);
  const [showPasswordResetScreen, setShowPasswordResetScreen] = useState(false);
  const [tempToken, setTempToken] = useState();
  const [resetEmail, setResetEmail] = useState("");
  const [resetLoading, setResetLoading] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [responseError, setResponseError] = useState("");
  const [submitting, setSubmitting] = useState(false);
  useEffect(() => {
    const path = window.location.pathname;
    if (path.includes("/reset-password")) {
      setShowPasswordResetScreen(true);
      // Extract the token from the URL
      const parts = path.split("/");
      const tokenIndex = parts.indexOf("reset-password") + 1;
      if (tokenIndex < parts.length) {
        const token = parts[tokenIndex];
        setTempToken(token);
      }
    }
  }, []);

  useEffect(() => {
    setPassword("");
  }, [signUp]);

  const handleLogin = async (e) => {
    e.preventDefault();

    ReactGA.event({
      category: "User",
      action: `Logged in. Account ${username}}`,
    });

    setLoading(true);
    try {
      const body = await Authenticate(username, password);
      if (body) {
        LogRocket.identify(body.name);
        setToken(body);
      }
    } catch (err) {
      if (err) setError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await SignUp(username, account);

      if (response) {
        setTempToken(response);
        setCreatePassword(true);
      }
    } catch (err) {
    } finally {
    }
    setLoading(false);
  };

  const handleSetPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await SetPassword(username, password, tempToken);

      if (response) {
        setCreatePassword(false);
        setSignUp(false);
        setError("Password set. Please log in.");
      }
    } catch (err) {
    } finally {
    }
  };
  const handleResetPasswordClicked = (e) => {
    debugger;
    e.preventDefault();
    localStorage.clear();
    setToken(null);
    setIsResetPassword(true);
  };
  const RenderLogin = () => {
    return (
      <>
        <form onSubmit={handleLogin} className="box">
          <article className="media">
            <div className="media-content">
              <div className="image">
                <img src={logo} alt="logo" />
              </div>
            </div>
          </article>
          <div className="field">
            <label htmlFor="" className="label">
              Account Number or Email
            </label>
            <div className="control has-icons-left">
              <input
                type="text"
                className="input"
                placeholder="e.g. 1111"
                onChange={(e) => setUsername(e.target.value)}
                required
              />
              <span className="icon is-small is-left">
                <FontAwesomeIcon icon={faEnvelope} />
              </span>
            </div>
          </div>
          <div className="field">
            <label htmlFor="" className="label">
              Password
            </label>
            <div
              className="control has-icons-left has-icons-right"
              style={{ display: "inline-block" }}
            >
              <input
                type={showPasswords ? "text" : "password"}
                placeholder="*******"
                autoComplete="current-password"
                className="input"
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <span className="icon is-small is-left">
                <FontAwesomeIcon icon={faLock} />
              </span>
            </div>
            <i
              style={{
                display: "inline-block",
                zIndex: 999,
                position: "absolute",
                marginTop: "10px",
                marginLeft: "-25px",
              }}
              className="icon is-small is-right"
              onClick={() => setShowPasswords(!showPasswords)}
            >
              <FontAwesomeIcon icon={faEye} />
            </i>
          </div>
          <div className="field has-text-right">
            {error ? (
              <span
                className="has-text-danger"
                style={{
                  marginTop: "7px",
                  display: "inline-block",
                  float: "left",
                }}
              >
                {error}
              </span>
            ) : (
              ""
            )}
            <button
              className={`button is-primary is-rounded has-text-weight-bold ${
                loading && "is-loading"
              }`}
            >
              Login
            </button>
            <Link
              onClick={
                (e) => handleResetPasswordClicked(e)
                //   {
                //   e.preventDefault();
                //   setIsResetPassword(true);
                // }
              }
              className="button is-rounded has-text-weight-bold"
              style={{ marginLeft: "10px" }}
            >
              Reset Password
            </Link>
          </div>
          <div className="is-align-content-center has-text-centered">
            Have multiple accounts?
            <button
              className="button is-info is-rounded has-text-weight-bold"
              onClick={(e) => {
                e.preventDefault();
                setSignUp(true);
              }}
            >
              Sign Up Here
            </button>
          </div>
        </form>
      </>
    );
  };

  const renderSetPassword = () => {
    return (
      <>
        <form onSubmit={handleSetPassword}>
          <div className="field">
            <label htmlFor="" className="label">
              New Password
            </label>
            <div
              className="control has-icons-left has-icons-right"
              style={{ display: "inline-block" }}
            >
              <input
                type={showPasswords ? "text" : "password"}
                className="input"
                onChange={(e) => setPassword(e.target.value)}
                required
                value={password}
              />
              <span className="icon is-small is-left">
                <FontAwesomeIcon icon={faLock} />
              </span>
            </div>
            <i
              style={{
                display: "inline-block",
                zIndex: 999,
                position: "absolute",
                marginTop: "10px",
                marginLeft: "-25px",
              }}
              className="icon is-small is-right"
              onClick={() => setShowPasswords(!showPasswords)}
            >
              <FontAwesomeIcon icon={faEye} />
            </i>
          </div>
          <div className="has-text-centered">
            <button
              type="submit"
              cclassName={`button is-primary is-rounded has-text-weight-bold ${
                loading && "is-loading"
              }`}
            >
              Set Password
            </button>
          </div>
        </form>
      </>
    );
  };

  const renderSignUp = () => {
    return (
      <>
        <div className="box">
          <article className="media">
            <div className="media-content">
              <div className="image">
                <img src={logo} alt="logo" />
              </div>
            </div>
          </article>
          {createPassword === true ? (
            renderSetPassword()
          ) : (
            <>
              <form onSubmit={handleSignUp}>
                <div className="field">
                  <label htmlFor="" className="label">
                    Email
                  </label>
                  <div className="control has-icons-left">
                    <input
                      type="text"
                      className="input"
                      placeholder=""
                      onChange={(e) => setUsername(e.target.value)}
                      required
                    />
                    <span className="icon is-small is-left">
                      <FontAwesomeIcon icon={faEnvelope} />
                    </span>
                  </div>
                  <div className="field">
                    <label htmlFor="" className="label">
                      Any of your account numbers (enter only one)
                    </label>
                    <div className="control has-icons-left">
                      <input
                        type="text"
                        className="input"
                        onChange={(e) => setAccount(e.target.value)}
                        required
                      />
                      <span className="icon is-small is-left">
                        <FontAwesomeIcon icon={faFileInvoice} />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="field has-text-right">
                  <button
                    className={`button is-primary is-rounded has-text-weight-bold ${
                      loading && "is-loading"
                    }`}
                  >
                    Sign up
                  </button>
                </div>
              </form>
            </>
          )}
        </div>
      </>
    );
  };

  const renderResetPassword = () => {
    const handleResetPassword = async (e) => {
      e.preventDefault();
      setResetLoading(true);
      try {
        const body = await ResetPasswordRequest(resetEmail);
        setResetLoading(false);
        setIsResetPassword(false);
        alert("Password reset link has been sent to your email.");
      } catch (error) {
        console.error(
          "Error occurred while sending password reset link:",
          error
        );
      }
    };

    const handleCancel = () => {
      window.location.reload();
    };

    return (
      <div className="box">
        <article className="media">
          <div className="media-content">
            <div className="image">
              <img src={logo} alt="logo" />
            </div>
          </div>
        </article>
        <form onSubmit={handleResetPassword}>
          <div className="field">
            <label htmlFor="" className="label">
              Account Number or Email
            </label>
            <div className="control has-icons-left">
              <input
                className="input"
                placeholder=" Account or Email"
                value={resetEmail}
                onChange={(e) => setResetEmail(e.target.value)}
                required
              />
              <span className="icon is-small is-left">
                <FontAwesomeIcon icon={faEnvelope} />
              </span>
            </div>
          </div>
          <div className="field is-grouped has-text-right">
            <div className="control">
              <button
                type="submit"
                className={`button is-primary is-rounded has-text-weight-bold ${
                  resetLoading && "is-loading"
                }`}
              >
                Reset Password
              </button>
            </div>
            <div className="control">
              <button
                type="button"
                className="button is-rounded has-text-weight-bold"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  };

  const renderResetPasswordResponse = () => {
    const handleResetPasswordResponse = async (e) => {
      e.preventDefault();
      if (newPassword !== confirmPassword) {
        setError("Passwords do not match.");
        return;
      }
      if (
        newPassword.length < 4 ||
        confirmPassword.length < 4 ||
        newPassword.length > 8 ||
        confirmPassword.length > 8
      ) {
        setError("Passwords must be between 4-8 characters long.");
        return;
      }

      setSubmitting(true);
      setError("");
      try {
        const response = await ResetPasswordResponse(tempToken, newPassword);
        console.log("Reset password response:", response);
        alert("Password reset successfully.");
        window.labOrderViewWindow = window.open("/");
        setNewPassword("");
        setConfirmPassword("");
        setResponseError("");
      } catch (error) {
        console.error("Error resetting password:", error);
        setResponseError("An error occurred while resetting password.");
      } finally {
        setSubmitting(false);
      }
    };

    return (
      <>
        <form onSubmit={handleResetPasswordResponse} className="box">
          <article className="media">
            <div className="media-content">
              <div className="image">
                <img src={logo} alt="logo" />
              </div>
            </div>
          </article>
          <div className="field">
            <label htmlFor="" className="label">
              Password
            </label>
            <div
              className="control has-icons-left has-icons-right"
              style={{ display: "inline-block" }}
            >
              <input
                type={showPasswords ? "text" : "password"}
                placeholder="*******"
                autoComplete="current-password"
                className="input"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
              <span className="icon is-small is-left">
                <FontAwesomeIcon icon={faLock} />
              </span>
            </div>
            <i
              style={{
                display: "inline-block",
                zIndex: 999,
                position: "absolute",
                marginTop: "10px",
                marginLeft: "-25px",
              }}
              className="icon is-small is-right"
              onClick={() => setShowPasswords(!showPasswords)}
            >
              <FontAwesomeIcon icon={faEye} />
            </i>
          </div>

          <div className="field">
            <label htmlFor="" className="label">
              Confirm Password
            </label>
            <div
              className="control has-icons-left has-icons-right"
              style={{ display: "inline-block" }}
            >
              <input
                type={showPasswords ? "text" : "password"}
                placeholder="*******"
                autoComplete="current-password"
                className="input"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
              <span className="icon is-small is-left">
                <FontAwesomeIcon icon={faLock} />
              </span>
            </div>
            <i
              style={{
                display: "inline-block",
                zIndex: 999,
                position: "absolute",
                marginTop: "10px",
                marginLeft: "-25px",
              }}
              className="icon is-small is-right"
              onClick={() => setShowPasswords(!showPasswords)}
            >
              <FontAwesomeIcon icon={faEye} />
            </i>
          </div>
          <div className="field has-text-right">
            {error ? (
              <span
                className="has-text-danger"
                style={{
                  marginTop: "7px",
                  display: "inline-block",
                  float: "left",
                }}
              >
                {error}
              </span>
            ) : (
              ""
            )}
            <button
              className={`button is-primary is-rounded has-text-weight-bold ${
                loading && "is-loading"
              }`}
            >
              Update
            </button>
          </div>
        </form>
      </>
    );
  };

  return (
    <section className="hero is-info is-fullheight">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-5-table is-4-desktop is-3-widescreen">
              {showPasswordResetScreen
                ? renderResetPasswordResponse()
                : isResetPassword
                ? renderResetPassword()
                : signUp
                ? renderSignUp()
                : RenderLogin()}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
};
