import React from "react";
import { Link } from "react-router-dom";

export function GetBottleOrderColumns() {
  return [
    {
      Header: "Order Number",
      accessor: "bottleOrderNumber",
      // disableSortBy: true,
      Cell: ({ cell }) => {
        return (
          <>
            <span
              className="has-text-primary has-text-weight-semibold"
              // to={`/bottleorder/${cell.row.values.bottleOrderNumber}`}
              style={{ paddingLeft: "30px" }}
            >
              {cell.row.values.bottleOrderNumber}
            </span>
          </>
        );
      },
    },
    {
      Header: "Description",
      accessor: "description",
      style: { textAlign: "center" },
      // disableSortBy: true,
      Cell: ({ cell }) => {
        return <span className={``}>{cell.row.values.description}</span>;
      },
    },
    {
      Header: "Scheduled To Be Shipped",
      accessor: "dueDate",
      style: { textAlign: "right" },
      // disableSortBy: true,
      Cell: ({ cell }) => {
        let d = new Date(cell.row.values.dueDate);

        return <span>{d.toLocaleDateString("en-US")}</span>;
      },
    },
  ];
}
