import React, { Fragment, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, } from "@fortawesome/free-solid-svg-icons";

export default function CommonLoader({ loading, color }) {
    return (
        <Fragment>
            {loading ? (
                // Use our custom loading state to show a loading indicator
                <span
                    className="icon is-medium"
                    style={{ position: "absolute", marginLeft: "20px", }}
                >
                    <FontAwesomeIcon
                        className={`fa-spin has-text-${color}`}
                        icon={faSpinner}
                        style={{ height: "50px", width: "50px" }}
                    />
                </span>
            ) : (
                ""
            )}
        </Fragment>
    );
}
