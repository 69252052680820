import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";

/**
 * Renders a dropdown and the children components
 * @param {Visible} bool : set to false to hide dropdown
 * @param {children} arrayofcomponents   children to display in dropdown.
 * in div with dropdown-item class.
 * @returns Dropdown div
 */
export default function Dropdown({ visible = true, children }) {
  const [dropdown, setDropdown] = useState(false);
  const node = useRef();

  const handleClick = (e) => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    setDropdown(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  return (
    <>
      {visible && (
        <div
          className={`dropdown is-pulled-right ${dropdown && "is-active"}`}
          ref={node}
        >
          <div className="dropdown-trigger">
            <button
              className="button is-primary is-outlined is-rounded"
              aria-haspopup="true"
              aria-controls="dropdown-menu"
              onClick={() => setDropdown(!dropdown)}
            >
              <span>Downloads</span>
              <span class="icon is-small">
                <FontAwesomeIcon icon={faAngleDown} />
              </span>
            </button>
          </div>
          <div className="dropdown-menu" id="dropdown-menu" role="menu">
            <div className="dropdown-content">
              {children && children.map((Component) => <>{Component}</>)}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

Dropdown.propTypes = {
  visible: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element),
};
