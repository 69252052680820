import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from "react-router-dom";
import Login from "./components/Login";
import Dashboard from "./components/dashboard/ClientDashboard";
import useToken from "./services/useToken";
import Header from "./components/Header";
import Order from "./components/order/Order";
import BottleOrder from "./components/bottleorders/bottleorder";
import Invoice from "./components/invoice/Invoice";
import { useEffect, useState } from "react";
import MultiClientDashboard from "./components/dashboard/MultiClientDashboard";
import useGaTracker from "./usGaTracker";
import LogRocket from "logrocket";
import Client from "./components/client/Client";
import { NotificationContainer } from "react-notifications";

LogRocket.init("ghozmy/vlp-client");

const RenderBody = ({ logout, token }) => {
	const [selectedClient, setSelectedClient] = useState();

	useEffect(() => {		
		if (token.accountNumbers.length === 0) {			
			setSelectedClient(token.accountNumber);
		}
		// if (token.accountNumbers.length > 0) {
		// 	setSelectedClient(token.accountNumbers[0]);
		// }
	}, [token]);

	return (		
		<div className="container">
			<NotificationContainer leaveTimeout={3000} />
			<Header
				logout={logout}
				accountNumber={selectedClient}
				isMultiClient={token.accountNumbers.length > 0}
				clearClient={() => {
					setSelectedClient();
					window.location.href = window.location.origin;
				}}
			/>
			<div>
				<div>
					<Switch>
						{useGaTracker()}
						<Redirect exact from="/" to="dashboard" />
						<Route exact path="/dashboard">
							{token.accountNumbers.length > 0 && !selectedClient ? (
								<MultiClientDashboard
									logout={logout}
									token={token}
									setAccount={setSelectedClient}
								/>
							) : (
								<Dashboard logout={logout} accountNumber={selectedClient} />
							)}
						</Route>
						<Route exact path="/dashboard/:acc">
							<Dashboard logout={logout} accountNumber={selectedClient} />
						</Route>
						<Route path="/order/:orderNumber" component={Order}></Route>
						<Route exact path="/client">
							<Client accountNumber={selectedClient} />
						</Route>
						<Route path="/invoice/:invoiceNumber" component={Invoice}></Route>
						<Route
							path="/bottleorder/:orderNumber"
							component={BottleOrder}
						></Route>
					</Switch>
				</div>
			</div>
		</div>
	);
};

function App() {
	const { token, setToken, logout } = useToken();

	if (!token) {
		return <Login setToken={setToken} />;
	}

	const now = Date.now().valueOf();
	const tokeExp = token.expiration && Date.parse(token.expiration);

	return (
		<>
			{tokeExp < now ? (
				<Login setToken={setToken} />
			) : (
				<RenderBody logout={logout} token={token} />
			)}
		</>
	);
}

export default App;
