import React from "react";
import { Link } from "react-router-dom";
import DownloadAnchor from "../common/DownloadAnchor";
import { faFilePdf, faLink } from "@fortawesome/free-solid-svg-icons";
import { getCoc, getLabReport } from "../../services/reportService";

export let OrderColumns = [
  {
    Header: "Order Number",
    accessor: "orderNumber",
    // disableSortBy: true,
    Cell: ({ cell }) => {
      return (
        <Link
          className="has-text-primary has-text-weight-semibold underscore"
          to={`/order/${cell.row.values.orderNumber}`}
        >
          {cell.row.values.orderNumber}
        </Link>
      );
    },
  },
  {
    Header: "Sample Date",
    accessor: "sampleDate",
    // disableSortBy: true,
    Cell: ({ cell }) => {
      let d = new Date(cell.row.values.sampleDate);

      return <span>{d.toLocaleDateString("en-US")}</span>;
    },
  },
  {
    Header: "Project",
    style: { textAlign: "center" },
    accessor: "project",
    // disableSortBy: true,
    Cell: ({ cell }) => {
      return (
        <p className="is-capitalized">
          {cell.row.values.project !==null ? cell.row.values.project.toLowerCase() : ""}
        </p>
      );
    },
  },
  {
    Header: "Status",
    style: { textAlign: "center" },
    accessor: "status",
    // disableSortBy: true,
    Cell: ({ cell }) => {
      let color = "primary";
      switch (cell.row.values.status.trim()) {
        case "Invoiced":
          color = "warning";
          break;
        case "Paid":
          color = "success";
          break;
        default:
          color = "primary";
      }
      return (
        <span className={`tag is-${color} is-rounded is-light`}>
          {cell.row.values.status}
        </span>
      );
    },
  },
  {
    Header: "Downloads",
    accessor: "reportUrl",
    disableSortBy: true,
    style: { textAlign: "center" },
    width: 50,
    Cell: ({ cell, data }) => {
      let d = data[cell.row.index];
      return (
        <>
          {d.hasReport === true ? (
            <DownloadAnchor
              cssClass="has-text-primary"
              func={() => getLabReport(d.orderNumber)}
              icon={faFilePdf}
              hoverText="Order Results"
            />
          ) : (
            ""
          )}
          {d.cocs?.map((s) => {
            return (
              <DownloadAnchor
                cssClass={"has-text-primary"}
                func={() => getCoc(s)}
                icon={faLink}
                hoverText="Chain of Custody"
              />
            );
          })}
        </>
      );
    },
  },
];
