import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import {getStripeApiKey, createStripeSession} from '../../services/stripeService'


export default function CheckoutButton({ invoices }) {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async (event) => {
    setIsLoading(true);

    try {
      const key = await getStripeApiKey();      
      const stripe = await loadStripe(key);

      if(!Array.isArray(invoices))
        invoices = new Array(invoices);

      const session = await createStripeSession(invoices);
      const result = await stripe.redirectToCheckout({ sessionId: session });

      if (result.error) {
        console.error(result);
      } 
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <button
      type="button"
      id="checkout-button"
      role="link"
      onClick={handleClick}
      className={`button is-primary is-small is-rounded ${isLoading && "is-loading"}`}     
      disabled={invoices.length === 0}
      title="Select unpaid invoices to pay"
    >
      <FontAwesomeIcon icon={faCreditCard} style={{ marginRight: "10px" }} />{" "}
      Pay
    </button>
  );
}
