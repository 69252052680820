import React, { Fragment, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

export default function Filter({ searchFunction, loading, color }) {
  const [searchInput, setsearchInput] = useState("");

  useEffect(() => {
      searchFunction(searchInput);
  }, [searchInput, searchFunction]);

  return (
    <Fragment>
      <div className="control has-icons-left">
        <input
          className={`input is-rounded is-${color}`}
          value={searchInput}
          placeholder="Search"
          onChange={(e) => setsearchInput(e.target.value)}
          disabled={loading}
        />
        <span className="icon is-small is-left">
          <FontAwesomeIcon icon={faSearch} />
        </span>
      </div>
    </Fragment>
  );
}
