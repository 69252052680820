/**
 * Get the JWT token from localstorage.  If there is no token redirect to login
 * @return {[string]}      JWT Token string
 */
function getToken() {
	const tokenString = localStorage.getItem("token");
	const userToken = JSON.parse(tokenString);
	if (
		userToken === null &&
		window.location.path !== "/" &&
		window.location.path !== undefined
	) {
		window.location.pathname = "/";
	}
	return userToken?.token;
}

function getBaseUrl() {
	let baseUrl = "https://rateus.envirolabsinc.com/vlpclient";
	if (process.env.NODE_ENV !== "production") {
	  baseUrl = "https://localhost:44349/vlpclient";
	}
	return baseUrl;
}

export async function getJson(path) {
	const myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${getToken()}`);

	let response = await fetch(`${getBaseUrl()}${path}`, {
		method: "GET",
		headers: myHeaders,
	});

	if (response.ok) {
		return response.json();
	}

	throw response;
}

export async function getText(path) {
	const myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${getToken()}`);

	let response = await fetch(`${getBaseUrl()}${path}`, {
		method: "GET",
		headers: myHeaders,
	});

	if (response.ok) {
		return response.text();
	}

	throw response;
}

export async function getBlob(path) {
	const myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${getToken()}`);

	let response = await fetch(`${getBaseUrl()}${path}`, {
		method: "GET",
		headers: myHeaders,
	});

	if (response.ok) {
		return response.blob();
	}

	throw response;
}

export async function post(path, dataObject) {
	const headers = new Headers();
	headers.append("Authorization", `Bearer ${getToken()}`);

	let data = {
		method: "POST",
		headers: new Headers({
			"Content-Type": "application/json",
			Accept: "application/json",
		}),

		body: JSON.stringify(dataObject),
	};

	let response = await fetch(`${getBaseUrl()}${path}`, data);

	if (response.ok) {
		return response.json();
	}

	throw await response.json();
}

export async function put(path, dataObject) {
	const headers = new Headers();	
  
	let data = {
		method: "PUT",
		headers: new Headers({
			"Content-Type": "application/json",
			"Authorization": `Bearer ${getToken()}`,
			Accept: "application/json"
		}),
		body: JSON.stringify(dataObject),
	};
  
	try {				
	  const response = await fetch(`${getBaseUrl()}${path}`, data);  
	  if (response.ok) {
		return response.json();
	  }  
	  const errorResponse = await response.json();
	  throw new Error(`Request failed with status ${response.status}: ${errorResponse.message}`);
	} catch (error) {
	  throw new Error(`Error while updating data: ${error.message}`);
	}
  }

