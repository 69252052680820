import { useEffect, useState, useMemo } from "react";
import {useHistory} from 'react-router-dom';
import Table from "../common/Table";
import {getMultiClientDashboard} from '../../services/dashboardService'

export default function MultiClientDashboard({ token, setAccount }) {
  let history = useHistory();
  const [dashboardData, setDashboardData] = useState({
    loading: false,
    items: [],
  });

  const selectAccount = (acc) => {
    setAccount(acc.trim());
    history.push(`/dashboard/${acc}`);
  };

  useEffect(() => {
    async function loadDashboard() {
      setDashboardData({ ...dashboardData, loading: true });
      let data = await getMultiClientDashboard(token.name);
      if (data.length > 50) {
        setDashboardData({ loading: false, items: data.sort(function(a,b) {
          var nameA = a.accountNumber.toUpperCase(); // ignore upper and lowercase
          var nameB = b.accountNumber.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        }).slice(0, 50) });
      } else {
        setDashboardData({ loading: false, items: data.sort(function(a,b) {
          var nameA = a.accountNumber.toUpperCase(); // ignore upper and lowercase
          var nameB = b.accountNumber.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        }) });
      }
    }

    loadDashboard();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Account Number",
        accessor: "accountNumber",
        Cell: ({ cell }) => {
          return (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a onClick={() => selectAccount(cell.row.values.accountNumber)}>
              {cell.row.values.accountNumber}
            </a>
          );
        },
      },
      {
        Header: "Name",
        accessor: "clientName",
      },
      {
        Header: "Open Invoices",
        accessor: "openInvoices",
      },
      {
        Header: "Open Orders",
        accessor: "openOrders",
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <div>
        <Table
          columns={columns}
          data={dashboardData.items}
          loading={dashboardData.loading}
        />
      </div>
    </>
  );
}
