import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ProgressBar, Step } from "react-step-progress-bar";

export default function OrderProgressBar({ percentComplete }) {
  return (
    <ProgressBar
      percent={percentComplete}
      filledBackground="linear-gradient(to right, rgba(50,152,220,1) 0%, rgba(0,209,178,1) 100%)"
    >
      <Step transition="scale">
        {({ accomplished }) => {
          return (
            <div>
              <div className="has-text-centered">
                <FontAwesomeIcon
                  className="has-text-primary is-size-4"
                  icon={faCheckCircle}
                  style={{
                    filter: `grayscale(${accomplished ? 0 : 80}%)`,
                    background:
                      "radial-gradient(circle, rgba(255,255,255,1) 70%, rgba(255,255,255,0) 70%)",
                  }}
                />
                <p
                  className="has-text-weight-semibold is-primary"
                  style={{
                    position: "absolute",
                    marginTop: "10px",
                    marginLeft: "-20px",
                  }}
                >
                  Received
                </p>
              </div>
            </div>
          );
        }}
      </Step>
      <Step transition="scale">
        {({ accomplished }) => (
          <div>
            <div className="has-text-centered">
              <FontAwesomeIcon
                className="has-text-primary is-size-4"
                icon={faCheckCircle}
                style={{
                  filter: `grayscale(${accomplished ? 0 : 80}%)`,
                  background:
                    "radial-gradient(circle, rgba(255,255,255,1) 70%, rgba(255,255,255,0) 70%)",
                }}
              />
              <p
                className="has-text-weight-semibold is-primary"
                style={{
                  position: "absolute",
                  minWidth: "250px",
                  marginLeft: "-111px",
                  marginTop: "10px",
                }}
              >
                Analysis In Progress
              </p>
            </div>
          </div>
        )}
      </Step>
      <Step transition="scale">
        {({ accomplished }) => (
          <div>
            <div className="has-text-centered">
              <FontAwesomeIcon
                className="has-text-primary is-size-4"
                icon={faCheckCircle}
                style={{
                  filter: `grayscale(${accomplished ? 0 : 80}%)`,
                  background:
                    "radial-gradient(circle, rgba(255,255,255,1) 70%, rgba(255,255,255,0) 70%)",
                }}
              />
              <p
                className="has-text-weight-semibold is-primary"
                style={{
                  position: "absolute",
                  minWidth: "200px",
                  marginLeft: "-85px",
                  marginTop: "10px",
                }}
              >
                Analysis Complete
              </p>
            </div>
          </div>
        )}
      </Step>
      <Step transition="scale">
        {({ accomplished }) => (
          <div>
            <div className="has-text-centered">
              <FontAwesomeIcon
                className="has-text-primary is-size-4"
                icon={faCheckCircle}
                style={{
                  filter: `grayscale(${accomplished ? 0 : 80}%)`,
                  background:
                    "radial-gradient(circle, rgba(255,255,255,1) 70%, rgba(255,255,255,0) 70%)",
                }}
              />
              <p
                className="has-text-weight-semibold is-primary"
                style={{
                  position: "absolute",
                  minWidth: "200px",
                  marginLeft: "-87px",
                  marginTop: "10px",
                }}
              >
                Invoiced
              </p>
            </div>
          </div>
        )}
      </Step>
      <Step transition="scale">
        {({ accomplished }) => (
          <div>
            <div className="has-text-centered">
              <FontAwesomeIcon
                className="has-text-primary is-size-4"
                icon={faCheckCircle}
                style={{
                  filter: `grayscale(${accomplished ? 0 : 80}%)`,
                  background:
                    "radial-gradient(circle, rgba(255,255,255,1) 70%, rgba(255,255,255,0) 70%)",
                }}
              />
              <p
                className="has-text-weight-semibold is-primary"
                style={{
                  position: "absolute",
                  marginLeft: "-4px",
                  marginTop: "10px",
                }}
              >
                Paid
              </p>
            </div>
          </div>
        )}
      </Step>
    </ProgressBar>
  );
}
