import React from "react";
import { Link } from "react-router-dom";
import DownloadAnchor from "../common/DownloadAnchor";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { getInvoiceReport } from "../../services/reportService";

export function GetInvoiceColumns(handleMultiSelect) {
  return [
    {
      Header: "Invoice Number",
      accessor: "invoiceNumber",
      // disableSortBy: true,
      Cell: ({ cell }) => {
        let values = cell.row.values;
        return (
          <>
            {values.balance > 0 && (
              <input
                className="checkbox"
                type="checkbox"
                style={{ marginRight: "0px", height: "16px", width: "16px" }}
                onClick={() => handleMultiSelect(cell.row.values)}
              />
            )}
            <Link
              className="has-text-info has-text-weight-semibold underscore"
              to={`/invoice/${cell.row.values.invoiceNumber}`}
              style={{ paddingLeft: values.balance > 0 ? "15px" : "30px" }}
            >
              {cell.row.values.invoiceNumber}
            </Link>
          </>
        );
      },
    },
    {
      Header: "Order Number",
      accessor: "orderNumber",
      // disableSortBy: true,
      Cell: ({ cell }) => <span>{cell.row.values.orderNumber}</span>,
    },
    {
      Header: "Status",
      accessor: "status",
      style: { textAlign: "center" },
      // disableSortBy: true,
      Cell: ({ cell }) => {
        let color = "primary";
        switch (cell.row.values.status.trim()) {
          case "Unpaid":
            color = "warning";
            break;
          case "Paid":
            color = "success";
            break;
          default:
            color = "primary";
        }
        return (
          <span className={`tag is-${color} is-rounded is-light`}>
            {cell.row.values.status}
          </span>
        );
      },
    },
    {
      Header: "Date Sent",
      accessor: "sendDate",
      // disableSortBy: true,
      style: { textAlign: "right" },
      Cell: ({ cell }) => {
        let d = new Date(cell.row.values.sendDate);

        return <span>{d.toLocaleDateString("en-US")}</span>;
      },
    },
    {
      Header: "Amount",
      accessor: "amount",
      // disableSortBy: true,
      style: { textAlign: "right" },
      Cell: ({ cell }) => <span>${cell.row.values.amount}</span>,
      className: "",
    },
    {
      Header: "Balance",
      accessor: "balance",
      style: { textAlign: "right" },
      // disableSortBy: false,
      Cell: ({ cell }) => <span>${cell.row.values.balance}</span>,
    },
    {
      Header: "Invoice PDF",
      accessor: "invoiceUrl",
      style: { textAlign: "center" },
      disableSortBy: true,
      Cell: ({ cell, data }) => {
        return (
          <DownloadAnchor
            cssClass="has-text-info"
            func={() => getInvoiceReport(cell.row.values.invoiceNumber)}
            icon={faFilePdf}
            hoverText="Invoice PDF"
          />
        );
      },
    },
  ];
}
