import React, { useEffect, useState } from "react";
import DashboardTable from "./DashboardTable";
import { OrderColumns } from "./orderColumns";
import { GetInvoiceColumns } from "./invoiceColumns";
import { getDashboard } from "../../services/dashboardService";
import { getInvoices } from "../../services/invoiceService";
import { getOrders } from "../../services/orderService";
import { getBottleOrderDashboard } from "../../services/bottleOrderService";
import { GetBottleOrderColumns } from "./bottleOrderColumns";

export default function ClientDashboard({ accountNumber }) {
  const [dashboard, setDashboard] = useState({
    openOrders: 0,
    openInvoices: 0,
    outstandingBalance: 0,
    totalOrders: 0,
    name:''
  });
  const [selectedInvoices, setSelectedInvoices] = useState([]);

  useEffect(() => {
    async function GetDashboardInfo() {
      try {
        let data = await getDashboard(accountNumber);
        setDashboard(data);
      } catch (err) {
        console.error(err);
      }
    }
    if (accountNumber) {
      GetDashboardInfo();
    }
  }, [accountNumber]);

  const handleMultiSelect = (item) => {
    setSelectedInvoices((invoices) => {
      const invoiceInList = invoices.find((i) => i === item.invoiceNumber);
      if (invoiceInList) {
        return invoices.filter((i) => i !== item.invoiceNumber);
      } else {
        return [...invoices, item.invoiceNumber];
      }
    });
  };

  const invoiceColumns = React.useMemo(
    () => GetInvoiceColumns(handleMultiSelect),
    []
  );
  const orderColumns = React.useMemo(() => OrderColumns, []);
  const bottleOrderColumns = React.useMemo(() => GetBottleOrderColumns(), []);

  if (!accountNumber)
    return (
      <h3>
        You must select an account that you have access to from the main screen
      </h3>
    );
  return (
    <>
      <div className="hero is-info">
        <div className="hero-body">
          <div className="container">
            <p className="title">
              {dashboard.name && <>Hi, {dashboard.name}</>}
            </p>
            <div className="columns">
              <div className="column">
                <div className="box">
                  <p className="subtitle has-text-primary has-text-centered has-text-weight-bold is-size-6">
                    Open Orders
                  </p>
                  <p className="has-text-centered has-text-primary has-text-weight-semibold is-size-3">
                    {dashboard.openOrders}
                  </p>
                </div>
              </div>
              <div className="column">
                <div className="box">
                  <p className="subtitle has-text-info has-text-centered has-text-weight-bold is-size-6">
                    Open Invoices
                  </p>
                  <p className="has-text-centered has-text-info has-text-weight-semibold is-size-3">
                    {dashboard.openInvoices} - $
                    {dashboard.outstandingBalance.toFixed(2)}
                  </p>
                </div>
              </div>
              <div className="column">
                <div className="box">
                  <p className="subtitle has-text-success has-text-centered has-text-weight-bold is-size-6">
                    Total Orders
                  </p>
                  <p className="has-text-centered has-text-success has-text-weight-semibold is-size-3">
                    {dashboard.totalOrders}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DashboardTable
        accountNumber={accountNumber}
        title={"Orders"}
        columns={orderColumns}
        getData={getOrders}
        color={"primary"}
      />
      <DashboardTable
        accountNumber={accountNumber}
        title={"Invoices"}
        columns={invoiceColumns}
        getData={getInvoices}
        color={"info"}
        invoices={selectedInvoices}
      />
      <DashboardTable
        accountNumber={accountNumber}
        title={"Bottle Orders"}
        columns={bottleOrderColumns}
        getData={getBottleOrderDashboard}
        color={"info"}
        isFromBottleOrder={true}
      />
    </>
  );
}
