import React, { useState } from "react";
import { updateClientDetails } from "../../services/clientService";
import { NotificationManager } from "react-notifications";
import { ContactTypes } from '../../models/ContactTypes';

export default function BillingTab({ clientDetails, navigateToDashboard }) {
  const [formData, setFormData] = useState(clientDetails);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: name.includes("Type") ? +value : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await updateClientDetails(formData);
      if (response) {
        NotificationManager.success("Data updated successfully");
        navigateToDashboard();
      } else {
        NotificationManager.error("Error while updating data");
      }
    } catch (error) {
      NotificationManager.error("Error while updating data");
    }
  };

  return (
    <div className="container">
      <h3>Billing Information</h3>
      <form onSubmit={handleSubmit}>
        <div className="columns">
          <div className="column field">
            <label className="label">Billing Name</label>
            <div className="control">
              <input
                className="input"
                type="text"
                name="billingName"
                value={formData.billingName}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="column field">
            <label className="label">Billing Address</label>
            <div className="control">
              <input
                className="input"
                type="text"
                name="billingAddress"
                value={formData.billingAddress}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="column field">
            <label className="label">Billing Address 2</label>
            <div className="control">
              <input
                className="input"
                type="text"
                name="billingAddress2"
                value={formData.billingAddress2}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        <div className="columns">
          <div className="column field">
            <label className="label">Billing City</label>
            <div className="control">
              <input
                className="input"
                type="text"
                name="billingCity"
                value={formData.billingCity}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="column field">
            <label className="label">Billing State</label>
            <div className="control">
              <input
                className="input"
                type="text"
                name="billingState"
                value={formData.billingState}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="column field">
            <label className="label">Billing Zip</label>
            <div className="control">
              <input
                className="input"
                type="text"
                name="billingZip"
                value={formData.billingZip}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        <div className="columns">
          <div className="column field">
            <label className="label">Billing Phone 1 Type</label>
            <div className="control">
              <div className="select is-fullwidth">
                <select
                  name="billingPhone1Type"
                  value={formData.billingPhone1Type}
                  onChange={handleChange}
                >
                  {Object.entries(ContactTypes).map(([key, { text, value }]) => (
                    <option key={key} value={value}>
                      {text}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="column field">
            <label className="label">Billing Phone 1</label>
            <div className="control">
              <input
                className="input"
                type="text"
                name="billingPhone1"
                value={formData.billingPhone1}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="column field">
            <label className="label">Billing Contact</label>
            <div className="control">
              <input
                className="input"
                type="text"
                name="billingContact"
                value={formData.billingContact}
                onChange={handleChange}
              />
            </div>
          </div>

        </div>

        <div className="columns">
          <div className="column field">
            <label className="label">Billing Phone 1 Type</label>
            <div className="control">
              <div className="select is-fullwidth">
                <select
                  name="billingPhone2Type"
                  value={formData.billingPhone2Type}
                  onChange={handleChange}
                >
                  {Object.entries(ContactTypes).map(([key, { text, value }]) => (
                    <option key={key} value={value}>
                      {text}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="column field">
            <label className="label">Billing Phone 2</label>
            <div className="control">
              <input
                className="input"
                type="text"
                name="billingPhone2"
                value={formData.billingPhone2}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="column field">
            <label className="label">Billing Email</label>
            <div className="control">
              <input
                className="input"
                type="text"
                name="billingEmail"
                value={formData.billingEmail}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>


        <div className="columns">
          <div className="column field">
            <label className="label">Want to receive the Chain of Custody with your invoice?</label>
            <div className="control">
              <label className="checkbox">
                <input
                  type="checkbox"
                  name="shouldReceiveCoC"
                  checked={formData.shouldReceiveCoC}
                  onChange={(e) => setFormData({ ...formData, shouldReceiveCoC: e.target.checked })}
                />
                Yes, I want to receive CoC with Invoice.
              </label>
            </div>
          </div>

        </div>

        <div className="field">
          <div className="control">
            <button className="button is-primary" type="submit">
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
