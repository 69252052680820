import React, { useEffect, useState } from "react";
import Filter from "../common/Filter";
import Table from "../common/Table";
import CheckoutButton from "../invoice/Payment";

export default function DashboardTable({
  accountNumber,
  title,
  columns,
  getData,
  color,
  invoices,
  isFromBottleOrder
}) {
  const [data, setData] = useState({ loading: false, data: [] });
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [filter, setFilter] = useState("");
  const [sort, setSort] = useState("");
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  useEffect(() => {
    onSearch()
  }, [page]);

  useEffect(() => {
    onSort()
  }, [sort]);

  useEffect(() => {
    const now = new Date();
    const tenDaysAgo = new Date(now);
    let initialFromDateTime;
    let initialToDateTime;
    if (isFromBottleOrder) {
      tenDaysAgo.setDate(now.getDate() + 365);
      initialFromDateTime = now.toISOString().slice(0, 10);
      initialToDateTime = tenDaysAgo.toISOString().slice(0, 10) ;
      setFromDate(initialFromDateTime);
      setToDate(initialToDateTime);
    }
    else {
      tenDaysAgo.setDate(now.getDate() - 365);
      initialFromDateTime = tenDaysAgo.toISOString().slice(0, 10);
      initialToDateTime = now.toISOString().slice(0, 10);
      setFromDate(initialFromDateTime);
      setToDate(initialToDateTime);
    }

    setData({ loading: true, data: [] });
    async function GetData(pg) {
      let sortOrder = "";

      if (sort.length > 0) {
        let so = sort[0].desc === true ? "desc" : "";
        sortOrder = `${sort[0].id}${so}`;
      }

      let data = await getData(initialFromDateTime, initialToDateTime, accountNumber, pg, filter, sortOrder);
      setPages(data.pages);
      setData({ loading: false, data: data.data });
    }
    GetData(page);

  }, []);


  const onSearch = () => {

    if (fromDate !== undefined && toDate !== undefined) {
      setData({ loading: true, data: [] });
      async function GetData(pg) {
        let sortOrder = "";

        if (sort.length > 0) {
          let so = sort[0].desc === true ? "desc" : "";
          sortOrder = `${sort[0].id}${so}`;
        }

        let data = await getData(fromDate, toDate, accountNumber, pg, filter, sortOrder);
        setPages(data.pages);
        setData({ loading: false, data: data.data });
      }
      GetData(page);
    }

  }

  const onSort = () => {
    if (sort.length > 0) {
      const sortId = sort[0].id;
      const sortDesc = sort[0].desc;
      const sortedData = [...data.data];

      const compareFunction = (a, b) => {
        const aValue = a[sortId];
        const bValue = b[sortId];

        if (aValue < bValue) return sortDesc ? 1 : -1;
        if (aValue > bValue) return sortDesc ? -1 : 1;
        return 0;
      };

      sortedData.sort(compareFunction);
      setData({ ...data, data: sortedData });
    }
  };



  return (
    <div className="container" style={{ marginTop: "20px" }}>
      <div className="box">
        <div className="hero">
          <div className="hero-body" style={{ padding: "0" }}>
            <div className="container">
              <div className="columns">
                <div className="column">
                  <p
                    className={`title has-text-grey`}
                    style={{
                      display: "inline-block",
                      marginRight: "30px",
                      marginBottom: "5px",
                    }}
                  >
                    {title}
                  </p>
                  {title === "Invoices" && (
                    <CheckoutButton invoices={invoices} />
                  )}
                </div>
              </div>
              <hr />
              <div className="columns">
                <div className="column" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <label htmlFor="fromDate">From:</label>
                  <input
                    id="fromDate"
                    className="input"
                    type="date"
                    value={fromDate}
                    onChange={(e) => setFromDate(e.target.value)}
                    disabled={data.loading}
                  />
                </div>

                <div className="column" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <label htmlFor="toDate">To:</label>
                  <input
                    id="toDate"
                    className="input"
                    type="date"
                    value={toDate}
                    onChange={(e) => setToDate(e.target.value)}
                    disabled={data.loading}
                  />
                </div>

                <div className="column is-one-quarter">
                  <Filter
                    searchFunction={setFilter}
                    loading={data.loading}
                    color={color}
                  />
                </div>

                <div className="column">
                  <a class="button is-info"
                    onClick={onSearch}
                    disabled={data.loading}
                  >
                    Search
                  </a>
                </div>
              </div>
              <Table
                columns={columns}
                data={data.data}
                color={color}
                setPage={setPage}
                totalPage={pages}
                currentpage={page}
                loading={data.loading}
                setSortBy={setSort}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
