import { useState } from "react";

export default function useToken() {
  const getToken = () => {
    const tokenString = localStorage.getItem("token");
    const userToken = JSON.parse(tokenString);
    return userToken;
  };

  const [token, setToken] = useState(getToken());

  const saveToken = (userToken) => {
    localStorage.setItem("token", JSON.stringify(userToken));
    setToken(userToken);
  };

  const logout = () => {
    localStorage.clear();
    setToken(null);
    window.location.href = window.location.origin;
  };

  return {
    setToken: saveToken,
    token,
    logout,
  };
}
