import { faFilePdf, faLink, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import "react-step-progress-bar/styles.css";
import Sample from "./Sample";
import Slide from "react-reveal/Slide";
import Pulse from "react-reveal/RubberBand";
import DownloadAnchor from "../common/DownloadAnchor";
import OrderProgressBar from "./OrderProgressbar";
import Dropdown from "../common/Dropdown";
import { getOrder } from "../../services/orderService";
import { getCoc, getLabReport } from "../../services/reportService";

const ToDate = (input) => {
  if (input) {
    let date = new Date(input);
    let output = date.toLocaleDateString();
    //handle default date from API
    if (date.getYear() === 1) return "";
    return output;
  } else {
    return "";
  }
};

export default function Order() {
  const { orderNumber } = useParams();
  const [percentComplete, setPercentComplete] = useState(0);
  const [order, setOrder] = useState();
  const hasDownloads = useRef(false);
  const hasReport = useRef(false);

  useEffect(() => {
    async function GetOrder() {
      let data = await getOrder(orderNumber);

      hasReport.current =
        data.Status === "Analysis Complete" ||
        data.Status === "Reported" ||
        data.Status === "Invoiced" ||
        data.Status === "Paid";

      if (data.Cocs === true || hasReport) hasDownloads.current = true;

      setOrder(data);

      setTimeout(() => {
        switch (data.Status) {
          case "Analysis In Progress":
            setPercentComplete(25);
            break;
          case "Reported":
            setPercentComplete(50);
            break;
          case "Invoiced":
            setPercentComplete(75);
            break;
          default:
            setPercentComplete(100);
        }
      }, 1500);
    }

    GetOrder();
  }, [orderNumber]);

  return (
    <>
      {!order ? (
        <></>
      ) : (
        <Slide top>
          <div className="box">
            <div className="columns">
              <div className="column">
                <p
                  style={{ display: "inline-block" }}
                  className="title has-text-grey-dark"
                >
                  # {orderNumber}
                </p>
              </div>
              <div className="column">
                <Link
                  className="is-rounded is-pulled-right is-size-4"
                  to="/"
                  style={{ marginRight: "25px" }}
                >
                  <Pulse cascade delay={2000}>
                    <FontAwesomeIcon icon={faTimes} />
                  </Pulse>
                </Link>
                <Dropdown
                  visible={hasDownloads.current}
                  children={[
                    order.Cocs?.map((s) => {
                      return (
                        <div class="dropdown-item">
                          <DownloadAnchor
                            cssClass={"has-text-primary is-size-4"}
                            func={() => getCoc(s)}
                            icon={faLink}
                            hoverText="Chain of Custody"
                            style={{ marginRight: "5px" }}
                            text="Chain of Custody"
                          />
                        </div>
                      );
                    }),
                    <div class="dropdown-item">
                      {order.Status!=="Analysis In Progress" ? (
                        <DownloadAnchor
                        cssClass="has-text-primary  is-size-4"
                        icon={faFilePdf}
                        hoverText="Order Results"
                        func={() => getLabReport(orderNumber)}
                        text=" Order Results"
                      />
                      ) : (
                        ""
                      )}
                      
                    </div>,
                  ]}
                />
              </div>
            </div>
            <hr />
            <div className="columns">
              <div
                className="column"
                style={{ paddingRight: "50px", paddingLeft: "50px" }}
              >
                <OrderProgressBar percentComplete={percentComplete} />
              </div>
            </div>
            <div className="section">
              <p className="subtitle has-text-grey-dark">Project Info</p>
              <hr />
              <div className="columns">
                <div className="column is-three-quarters-mobile is-two-quarters-tablet is-one-quarter-desktop is-one-quarter-widescreen is-one-quarter-fullhd">
                  <div class="field">
                    <label class="label">Project Description</label>
                    <span>{order.ProjectCategory}</span>
                  </div>
                  <div class="field">
                    <label class="label">Order Type</label>
                    <span>{order.ProjectCategory}</span>
                  </div>
                  <div class="field">
                    <label class="label">PWS Number</label>
                    <span>{order.PwsNumber}</span>
                  </div>
                </div>
                <div className="column">
                  <div class="field">
                    <label class="label">Invoice</label>
                    <span>
                      {order.InvoiceNumber && (
                        <Link to={`/invoice/${order.InvoiceNumber}`}>
                          {order.InvoiceNumber}
                        </Link>
                      )}
                    </span>
                  </div>
                  <div class="field">
                    <label class="label">Date Received</label>
                    <span>{ToDate(order.DateReceived)}</span>
                  </div>
                  <div class="field">
                    <label class="label">Date Reported</label>
                    <span>{ToDate(order.DateReported)}</span>
                  </div>
                </div>
                <div className="column is-half "></div>
              </div>
            </div>
            <div className="section" style={{ paddingTop: "10px" }}>
              <p class="subtitle has-text-grey-dark">Samples</p>
              <hr />
              {order.Samples?.map((s) => {
                return <Sample key={s.LabId} sample={s} />;
              })}
            </div>
          </div>
        </Slide>
      )}
    </>
  );
}
