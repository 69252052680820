import React from "react";
const ToDate = (input) => {
  if (input) {
    let date = new Date(input);
    let output = date.toLocaleDateString();
    //handle default date from API
    if (date.getYear() === 1) return "";
    return output;
  } else {
    return "";
  }
};
export default function OrderedBottle({ bottle }) {
  return (
    <div>
      <div
        className="columns"
        style={{ marginBottom: "20px", marginLeft: "0px" }}
      >
        <div className="column blue-left">
          <div className="field">
            <label className="label">Test</label>
            <span>{bottle.Parameter}</span>
          </div>
        </div>
        <div className="column">
          <div class="field">
            <label class="label">Frequency</label>
            <span>{bottle.SampleFrequency}</span>
          </div>
        </div>{" "}
        <div className="column">
          <div class="field">
            <label class="label">Monitor Dates</label>
            <span>
              {ToDate(bottle.MonitorStart)} - {ToDate(bottle.MonitorEnd)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
