import React, { useState, useEffect } from "react";
import { updateClientDetails } from "../../services/clientService";
import { NotificationManager } from "react-notifications";
import { ContactTypes } from '../../models/ContactTypes';

export default function ShippingTab({ clientDetails, navigateToDashboard }) {

  const [formData, setFormData] = useState(clientDetails);
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: name.includes("Type") ? +value : value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await updateClientDetails(formData);
      if (response) {
        NotificationManager.success("Data updated successfully");
        navigateToDashboard();
      }
      else {
        NotificationManager.error("Error while updating data");
      }

    } catch (error) {
      NotificationManager.error("Error while updating data");
    }
  };

  return (
    <div className="container">
      <h3>Shipping Information</h3>
      <form onSubmit={handleSubmit}>

        {/* Row 1 */}
        <div className="columns">

          <div className=" column field">
            <label className="label">Shipping Company Name</label>
            <div className="control">
              <input
                className="input"
                type="text"
                name="shippingName"
                value={formData.shippingName}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="column field">
            <label className="label">Shipping Address</label>
            <div className="control">
              <input
                className="input"
                type="text"
                name="shippingAddress"
                value={formData.shippingAddress}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="column field">
            <label className="label">Shipping Address2</label>
            <div className="control">
              <input
                className="input"
                type="text"
                name="shipAddress2"
                value={formData.shipAddress2}
                onChange={handleChange}
              />
            </div>
          </div>


        </div>
        {/* Row 2 */}
        <div className="columns">

          <div className=" column field">
            <label className="label">Shipping State</label>
            <div className="control">
              <input
                className="input"
                type="text"
                name="shippingState"
                value={formData.shippingState}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="column field">
            <label className="label">Shipping City</label>
            <div className="control">
              <input
                className="input"
                type="text"
                name="shippingCity"
                value={formData.shippingCity}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="column field">
            <label className="label">Ship Zip</label>
            <div className="control">
              <input
                className="input"
                type="text"
                name="shippingZip"
                value={formData.shippingZip}
                onChange={handleChange}
              />
            </div>
          </div>


        </div>
        {/* Row 3 */}
        <div className="columns">

          <div className="column field">
            <label className="label">Shipping Phone 1 Type</label>
            <div className="control">
              <div className="select is-fullwidth">
                <select
                  name="shippingPhone1Type"
                  value={formData.shippingPhone1Type}
                  onChange={handleChange}
                >
                  {Object.entries(ContactTypes).map(([key, { text, value }]) => (
                    <option key={key} value={value}>
                      {text}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="column field">
            <label className="label">Shipping Phone 1</label>
            <div className="control">
              <input
                className="input"
                type="text"
                name="shippingPhone1"
                value={formData.shippingPhone1}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="column field">
            <label className="label">Shipping Contact</label>
            <div className="control">
              <input
                className="input"
                type="text"
                name="shippingContact"
                value={formData.shippingContact}
                onChange={handleChange}
              />
            </div>
          </div>




        </div>
        {/* Row 4 */}
        <div className="columns">
          <div className="column field">
            <label className="label">Shipping Phone 2 Type</label>
            <div className="control">
              <div className="select is-fullwidth">
                <select
                  name="shippingPhone2Type"
                  value={formData.shippingPhone2Type}
                  onChange={handleChange}
                >
                  {Object.entries(ContactTypes).map(([key, { text, value }]) => (
                    <option key={key} value={value}>
                      {text}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="column field">
            <label className="label">Shipping Phone 2</label>
            <div className="control">
              <input
                className="input"
                type="text"
                name="shippingPhone2"
                value={formData.shippingPhone2}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="column field">
            <label className="label">Shipping Email</label>
            <div className="control">
              <input
                className="input"
                type="text"
                name="shippingEmail"
                value={formData.shippingEmail}
                onChange={handleChange}
              />
            </div>
          </div>


        </div>

        <div className="field">
          <div className="control">
            <button className="button is-primary" type="submit">
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
