import { getBlob } from "./networkService";
import { saveAs } from "file-saver";

export async function getCoc(recordId) {
  let blob = await getBlob(`/reports/coc/${recordId}`);
  saveAs(blob, `${recordId}-ChainOfCustody.pdf`);
}

export async function getLabReport(orderNumber) {
  let blob = await getBlob(`/reports/lab/${orderNumber}`);
  saveAs(blob, `${orderNumber}.pdf`);
}

export async function getInvoiceReport(invoiceNumber) {
  let blob = await getBlob(`/reports/invoice/${invoiceNumber}`);
  saveAs(blob, `${invoiceNumber}.pdf`);
}
