import { post } from "./networkService";

function getBaseUrl() {
  let baseUrl;
  if (process.env.NODE_ENV !== "production") {
    baseUrl = "https://localhost:44349/vlpclient";
  } else {
    baseUrl = "https://rateus.envirolabsinc.com/vlpclient";
  }

  return baseUrl;
}

export async function Authenticate(username, password) {
  return post("/Authentication/login", { username, password });
}

export async function ResetPasswordRequest(accountOrEmail) {
  return post("/Authentication/reset-password-request", accountOrEmail);
}
export async function SignUp(username, accountNumber) {
  return post("/authentication", { username, accountNumber });
}
//reset-password-response
export async function ResetPasswordResponse(token, password) {
  return post("/Authentication/reset-password-response", { token, password });
}

export async function SetPassword(username, password, token) {
  const headers = new Headers();
  headers.append("Authorization", `Bearer ${token}`);
  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");

  let data = {
    method: "POST",
    headers: headers,
    body: JSON.stringify({ username, password }),
  };

  let response = await fetch(
    `${getBaseUrl()}/authentication/setpassword`,
    data
  );

  if (response.ok) {
    return response.json();
  }

  throw await response.json();
}
