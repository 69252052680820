import React, { useEffect } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CommonLoader from './CommonLoader';
import {
  faSpinner,
  faArrowUp,
  faArrowDown,
} from "@fortawesome/free-solid-svg-icons";

export default function Table({
  columns,
  data,
  color,
  loading,
  totalPage,
  currentpage,
  setPage,
  setSortBy,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      useControlledState: (state) => {
        return React.useMemo(
          () => ({
            ...state,
            pageIndex: currentpage,
          }),
          [state]
        );
      },
      initialState: { pageIndex: currentpage }, // Pass our hoisted table state
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: totalPage,
      manualSortBy: true,
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    if (setSortBy) setSortBy(sortBy);
  }, [sortBy]);

  return (
    <>
      <table
        {...getTableProps()}
        className="table is-striped is-hoverable is-fullwidth"
        style={{ marginBottom: "0" }}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th
                  {...column.getHeaderProps([
                    {
                      className: column.className,
                      style: column.style,
                    },
                    column.getSortByToggleProps(),
                  ])}
                  className={`has-text-grey`}
                >
                  {column.render("Header")}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <FontAwesomeIcon
                          className={`has-text-${color}`}
                          icon={faArrowDown}
                          style={{ height: "15px", width: "15px" }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          className={`has-text-${color}`}
                          icon={faArrowUp}
                          style={{ height: "15px", width: "15px" }}
                        />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: cell.column.style,
                        },
                      ])}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="column">
        <button
          className={"button is-small is-rounded is-" + color}
          onClick={() => {
            setPage(1);
          }}
          disabled={currentpage === 1 || loading}
        >
          first
        </button>{" "}
        <button
          className={"button is-small is-rounded  is-" + color}
          onClick={() => {
            setPage((s) => (s === 0 ? 1 : s - 1));
          }}
          disabled={currentpage === 1 || loading}
        >
          prev
        </button>{" "}
        <button
          className={"button is-small is-rounded  is-" + color}
          onClick={() => {
            setPage((s) => s + 1);
          }}
          disabled={currentpage === totalPage || loading}
        >
          next
        </button>{" "}
        <button
          className={"button is-small is-rounded is-" + color}
          onClick={() => {
            setPage(totalPage);
          }}
          disabled={currentpage === totalPage || loading}
        >
          last
        </button>{" "}
        <span
          className={color}
          style={{ display: "inline-block", marginTop: "6px" }}
        >
          Page{" "}
          <strong className={color}>
            {currentpage} of {pageOptions.length}
          </strong>{" "}
        </span>
        <CommonLoader loading={loading} color={color} />
      </div>
    </>
  );
}
