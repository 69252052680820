import React, { useState } from "react";
import { updateClientDetails } from "../../services/clientService";
import { NotificationManager } from "react-notifications";
import { ContactTypes } from '../../models/ContactTypes';

export default function ReportTab({ clientDetails, navigateToDashboard }) {
  const [formData, setFormData] = useState(clientDetails);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: name.includes("Type") ? +value : value,
    });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await updateClientDetails(formData);
      if (response) {
        NotificationManager.success("Data updated successfully");
        navigateToDashboard();
      } else {
        NotificationManager.error("Error while updating data");
      }
    } catch (error) {
      NotificationManager.error("Error while updating data");
    }
  };

  return (
    <div className="container">
      <h3>Report Information</h3>
      <form onSubmit={handleSubmit}>
        <div className="columns">
          <div className="column field">
            <label className="label">Reporting Name</label>
            <div className="control">
              <input
                className="input"
                type="text"
                name="reportingName"
                value={formData.reportingName}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="column field">
            <label className="label">Report Address</label>
            <div className="control">
              <input
                className="input"
                type="text"
                name="reportAddress"
                value={formData.reportAddress}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="column field">
            <label className="label">Report Address 2</label>
            <div className="control">
              <input
                className="input"
                type="text"
                name="reportAddress2"
                value={formData.reportAddress2}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        <div className="columns">
          <div className="column field">
            <label className="label">Report City</label>
            <div className="control">
              <input
                className="input"
                type="text"
                name="reportCity"
                value={formData.reportCity}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="column field">
            <label className="label">Report State</label>
            <div className="control">
              <input
                className="input"
                type="text"
                name="reportState"
                value={formData.reportState}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="column field">
            <label className="label">Report Zip</label>
            <div className="control">
              <input
                className="input"
                type="text"
                name="reportZip"
                value={formData.reportZip}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        <div className="columns">
          <div className="column field">
            <label className="label">Report Phone 1 Type</label>
            <div className="control">
              <div className="select is-fullwidth">
                <select
                  name="reportingPhone1Type"
                  value={formData.reportingPhone1Type}
                  onChange={handleChange}
                >
                  {Object.entries(ContactTypes).map(([key, { text, value }]) => (
                    <option key={key} value={value}>
                      {text}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="column field">
            <label className="label">Report Phone 1</label>
            <div className="control">
              <input
                className="input"
                type="text"
                name="reportPhone1"
                value={formData.reportPhone1}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="column field">
            <label className="label">Report Contact</label>
            <div className="control">
              <input
                className="input"
                type="text"
                name="reportContact"
                value={formData.reportContact}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        <div className="columns">
          <div className="column field">
            <label className="label">Report Phone 2 Type</label>
            <div className="control">
              <div className="select is-fullwidth">
                <select
                  name="reportingPhone2Type"
                  value={formData.reportingPhone2Type}
                  onChange={handleChange}
                >
                  {Object.entries(ContactTypes).map(([key, { text, value }]) => (
                    <option key={key} value={value}>
                      {text}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="column field">
            <label className="label">Report Phone 2</label>
            <div className="control">
              <input
                className="input"
                type="text"
                name="reportPhone2"
                value={formData.reportPhone2}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="column field">
            <label className="label">Report Email</label>
            <div className="control">
              <input
                className="input"
                type="text"
                name="reportEmail"
                value={formData.reportEmail}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        {/* Add more input fields for other report properties here */}

        <div className="field">
          <div className="control">
            <button className="button is-primary" type="submit">
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
